p{
    margin: 0px!important;
    line-height: 28px!important;
}

.ui.wlclight1.label, .ui.wlclight1.labels .label , .wlclight1   {
    background-color: #91aa71!important;
    border-color: #91aa71!important;
    color: #fff!important;

}

.wlclight1Dot {
    color: #91aa71!important;
}

.wlclight1Border {
    border-color: #91aa71!important;
}


.ui.wlclight2.label, .ui.wlclight2.labels .label ,.wlclight2 {
    background-color: #649fbc!important;
    border-color: #649fbc!important;
    color: #fff!important;
     
}
.wlclight2Dot {
    color: #649fbc!important;
}
.wlclight2Border {
    border-color: #649fbc!important;
}

.ui.wlclight3.label, .ui.wlclight3.labels .label , .wlclight3 {
    background-color: #8974a3!important;
    border-color: #8974a3!important;
    color: #fff!important;
     
}
.wlclight3Dot {
    color:#8974a3!important;
}

.wlclight3Border {
    border-color: #8974a3!important;
}


.ui.wlclight4.label, .ui.wlclight4.labels .label , .wlclight4{
    background-color: #5e5e70!important;
    border-color: #5e5e70!important;
    color: #fff!important;
     
}

.wlclight4Dot {
    color:#5e5e70!important;
}
.wlclight4Border {
    border-color: #5e5e70!important;
}

.ui.wlclight5.label, .ui.wlclight5.labels .label , .wlclight5 {
    background-color: #b7b4c1!important;
    border-color: #b7b4c1!important;
    color: #fff!important;
     
}

.wlclight5Dot {
    color:#b7b4c1!important;
}
.wlclight5Border {
    border-color: #b7b4c1!important;
}

.ui.wlclight6.label, .ui.wlclight6.labels .label , .wlclight6 {
    background-color: #737CB8!important;
    border-color: #737CB8!important;
    color: #fff!important;
     
}

.wlclight6Dot {
    color:#737CB8!important;
}
.wlclight6Border {
    border-color: #737CB8!important;
}

.ui.wlclight7.label, .ui.wlclight7.labels .label, .wlclight7  {
    background-color: #e0ba87!important;
    border-color: #e0ba87!important;
    color: #fff!important;
     
}

.wlclight7Dot {
    color:#e0ba87!important;
}
.wlclight7Border {
    border-color: #e0ba87!important;
}

.ui.wlclight8.label, .ui.wlclight8.labels .label, .wlclight8 {
    background-color: #b7d08f!important;
    border-color: #b7d08f!important;
    color: #fff!important;
     
}

.wlclight8Dot {
    color:#b7d08f!important;
}

.wlclight8Border {
    border-color: #b7d08f!important;
}

.ui.wlclight9.label, .ui.wlclight9.labels .label , .wlclight9{
    background-color: #82bfce!important;
    border-color: #82bfce!important;
    color: #fff!important;
     
}

.wlclight9Dot {
    color:#82bfce!important;
}

.wlclight9Border {
    border-color: #82bfce!important;
}

.ui.wlclight10.label, .ui.wlclight10.labels .label , .wlclight10 {
    background-color: #af7298!important;
    border-color: #af7298!important;
    color: #fff!important;
     
}
.wlclight10Dot {
    color:#af7298!important;
}
.wlclight10Border {
    border-color: #af7298!important;
}


.ui.wlclight11.label, .ui.wlclight11.labels .label , .wlclight11 {
    background-color:#836f68!important;
    border-color: #836f68!important;
    color: #fff!important;
     
}

.wlclight11Dot {
    color:#836f68!important;
}
.wlclight11Border {
    border-color: #836f68!important;
}

.ui.wlclight12.label, .ui.wlclight12.labels .label , .wlclight12 {
    background-color: #76b7a8!important;
    border-color: #76b7a8!important;
    color: #fff!important;
     
}

.wlclight12Dot {
    color:#76b7a8!important;
}
.wlclight12Border {
    border-color: #76b7a8!important;
}


.ui.wlclight13.label, .ui.wlclight13.labels .label , .wlclight13 {
    background-color: #c3ce76!important;
    border-color: #c3ce76!important;
    color: #fff!important;
     
}

.wlclight13Dot {
    color:#c3ce76!important;
}
.wlclight13Border {
    border-color: #c3ce76!important;
}

.ui.wlclight14.label, .ui.wlclight14.labels .label , .wlclight14 {
    background-color: #709998!important;
    border-color: #709998!important;
    color: #fff!important;
     
}

.wlclight14Dot {
    color:#709998!important;
}
.wlclight14Border {
    border-color: #709998!important;
}

.ui.wlclight15.label, .ui.wlclight15.labels .label , .wlclight15 {
    background-color: #8a9fbc!important;
    border-color: #8a9fbc!important;
    color: #fff!important;
     
}
.wlclight15Dot {
    color: #8a9fbc!important;
}
.wlclight15Border {
    border-color: #8a9fbc!important;
}

.ui.wlclight16.label, .ui.wlclight16.labels .label , .wlclight16{
    background-color: #b7a3a3!important;
    border-color: #b7a3a3!important;
    color: #fff!important;
     
}

.wlclight16Dot {
    color: #b7a3a3!important;
}

.wlclight16Border {
    border-color: #b7a3a3!important;
}

.ui.wlclight17.label, .ui.wlclight17.labels .label , .wlclight17{
    background-color: #DC0000!important;
    border-color: #DC0000!important;
    color: #fff!important;
}

.wlclight17Dot {
    color: #DC0000!important;
}

.wlclight17Border {
    border-color: #DC0000!important;
}

.ui.wlclight18.label, .ui.wlclight18.labels .label , .wlclight18{
    background-color: #FFCC00!important;
    border-color: #FFCC00!important;
    color: #fff!important;
}

.wlclight18Dot {
    color: #FFCC00!important;
}

.wlclight18Border {
    border-color: #FFCC00!important;
}

.ui.wlclight19.label, .ui.wlclight19.labels .label , .wlclight19{
    background-color: #432600!important;
    border-color: #432600!important;
    color: #fff!important;
}

.wlclight19Dot {
    color: #432600!important;
}

.wlclight19Border {
    border-color: #432600!important;
}

.ui.wlclight20.label, .ui.wlclight20.labels .label , .wlclight20{
    background-color: #85BD41!important;
    border-color: #85BD41!important;
    color: #fff!important;
}

.wlclight20Dot {
    color: #85BD41!important;
}

.wlclight20Border {
    border-color: #85BD41!important;
}

.ui.wlclight21.label, .ui.wlclight21.labels .label , .wlclight21{
    background-color: #DB6E6E80!important;
    border-color: #DB6E6E80!important;
    color: #fff!important;
}

.wlclight21Dot {
    color: #DB6E6E80!important;
}

.wlclight21Border {
    border-color: #DB6E6E80!important;
}

.ui.wlclight22.label, .ui.wlclight22.labels .label , .wlclight22{
    background-color: #DBA4A440!important;
    border-color: #DBA4A440!important;
    color: #fff!important;
}

.wlclight22Dot {
    color: #DBA4A440!important;
}

.wlclight22Border {
    border-color: #DBA4A440!important;
}

.ui.wlclight23.label, .ui.wlclight23.labels .label , .wlclight23{
    background-color: #FFE68080!important;
    border-color: #FFE68080!important;
    color: #fff!important;
}

.wlclight23Dot {
    color: #FFE68080!important;
}

.wlclight23Border {
    border-color: #FFE68080!important;
}

.ui.wlclight24.label, .ui.wlclight24.labels .label , .wlclight24{
    background-color: #42342180!important;
    border-color: #42342180!important;
    color: #fff!important;
}

.wlclight24Dot {
    color: #42342180!important;
}

.wlclight24Border {
    border-color: #42342180!important;
}

.ui.wlclight25.label, .ui.wlclight25.labels .label , .wlclight25{
    background-color: #423B3240!important;
    border-color: #423B3240!important;
    color: #fff!important;
}

.wlclight25Dot {
    color: #423B3240!important;
}

.wlclight25Border {
    border-color: #423B3240!important;
}

.ui.wlclight26.label, .ui.wlclight26.labels .label , .wlclight26{
    background-color: #A1BD7E80!important;
    border-color: #A1BD7E80!important;
    color: #fff!important;
}

.wlclight26Dot {
    color: #A1BD7E80!important;
}

.wlclight26Border {
    border-color: #A1BD7E80!important;
}

.ui.wlclight27.label, .ui.wlclight27.labels .label , .wlclight27{
    background-color: #AEBD9D40!important;
    border-color: #AEBD9D40!important;
    color: #fff!important;
}

.wlclight27Dot {
    color: #AEBD9D40!important;
}

.wlclight27Border {
    border-color: #AEBD9D40!important;
}

.ui.wlclight28.label, .ui.wlclight28.labels .label , .wlclight28{
    background-color: #294B82!important;
    border-color: #294B82!important;
    color: #fff!important;
}

.wlclight28Dot {
    color: #294B82!important;
}

.wlclight28Border {
    border-color: #294B82!important;
}

.ui.wlclight29.label, .ui.wlclight29.labels .label , .wlclight29{
    background-color: #56678280!important;
    border-color: #56678280!important;
    color: #fff!important;
}

.wlclight29Dot {
    color: #56678280!important;
}

.wlclight29Border {
    border-color: #56678280!important;
}

.ui.wlclight30.label, .ui.wlclight30.labels .label , .wlclight30{
    background-color: #6C748240!important;
    border-color: #6C748240!important;
    color: #fff!important;
}

.wlclight30Dot {
    color: #6C748240!important;
}

.wlclight30Border {
    border-color: #6C748240!important;
}

.ui.wlclight31.label, .ui.wlclight31.labels .label , .wlclight31{
    background-color: #8EAEDB!important;
    border-color: #8EAEDB!important;
    color: #fff!important;
}

.wlclight31Dot {
    color: #8EAEDB!important;
}

.wlclight31Border {
    border-color: #8EAEDB!important;
}

.ui.wlclight32.label, .ui.wlclight32.labels .label , .wlclight32{
    background-color: #B6C6DB80!important;
    border-color: #B6C6DB80!important;
    color: #fff!important;
}

.wlclight32Dot {
    color: #B6C6DB80!important;
}

.wlclight32Border {
    border-color: #B6C6DB80!important;
}

.ui.wlclight33.label, .ui.wlclight33.labels .label , .wlclight33{
    background-color: #C8D0DB40!important;
    border-color: #C8D0DB40!important;
    color: #fff!important;
}

.wlclight33Dot {
    color: #C8D0DB40!important;
}

.wlclight33Border {
    border-color: #C8D0DB40!important;
}

/* Strong styles */

.ui.wlcstrong1.label, .ui.wlcstrong1.labels .label, .wlcstrong1 {
    background-color: #4E4E87!important;
    border-color: #4E4E87!important;
    color: #fff!important;

}



.wlcstrong1Dot {
    color: #4E4E87!important;
}

.ui.wlcstrong2.label, .ui.wlcstrong2.labels .label , .wlcstrong2{
    background-color: #c55f52!important;
    border-color: #c55f52!important;
    color: #fff!important;

}

.wlcstrong2Dot {
    color: #c55f52!important;
}

.ui.wlcstrong3.label, .ui.wlcstrong3.labels .label, .wlcstrong3 {
    background-color: #9f3677!important;
    border-color: #9f3677!important;
    color: #fff!important;

}


.wlcstrong3Dot {
    color: #9f3677!important;
}

.ui.wlcstrong4.label, .ui.wlcstrong4.labels .label , .wlcstrong4 {
    background-color: #62a571!important;
    border-color: #62a571!important;
    color: #fff!important;

}

.wlcstrong4Dot {
    color: #62a571!important;
}

.ui.wlcstrong5.label, .ui.wlcstrong5.labels .label, .wlcstrong5 {
    background-color: #427b9c!important;
    border-color: #427b9c!important;
    color: #fff!important;
}

.wlcstrong5Dot {
    color: #427b9c!important;
}

.ui.wlcstrong6.label, .ui.wlcstrong6.labels .label , .wlcstrong6{
    background-color: #ad3434!important;
    border-color: #ad3434!important;
    color: #fff!important;
     
}

.wlcstrong6Dot {
    color: #ad3434!important;
}

.ui.wlcstrong7.label, .ui.wlcstrong7.labels .label , .wlcstrong7{
    background-color: #e5bb26!important;
    border-color: #e5bb26!important;
    color: #fff!important;
     
}

.wlcstrong7Dot {
    color: #e5bb26!important;
}


.markedOccurrence_wlclight1 {
    background: #91aa71 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight2 {
    background: #649fbc !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight3 {
    background: #8974a3 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight4 {
    background: #5e5e70 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight5 {

    background: #b7b4c1 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight6 {
    background: #737CB8 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight7 {
    background: #e0ba87 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight8 {
    background: #b7d08f !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight9 {
    background: #82bfce!important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight10 {
    background: #af7298 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight11 {
    background: #836f68 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight12 {
    background: #76b7a8 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight13 {
    background: #c3ce76 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight14 {
    background: #709998 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight15 {
    background: #8a9fbc !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlclight16 {
    background: #b7a3a3 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}

.markedOccurrence_wlcstrong1 {
    background: #4E4E87 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlcstrong2 {
    background: #c55f52 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlcstrong3 {
    background: #9f3677 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlcstrong4 {
    background: #62a571 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlcstrong5 {
    background: #427b9c !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlcstrong6 {
    background: #ad3434 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}
.markedOccurrence_wlcstrong7 {
    background: #e5bb26 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}

.markedOccurrence_focused {
    border: 5px double!important;
}

.markedSearchTerm {
    border: 3px solid !important;
    padding: 2px !important;
}
.focusedSearchTerm {
    border: 5px double !important;
    padding: 2px !important;
}

/* colors for bvger */

.ui.bvgercolor1.label, .ui.bvgercolor1.labels .label , .bvgercolor1   {
    background-color: #007fa5!important;
    border-color: #007fa5!important;
    color: #fff!important;

}

.bvgercolor1Dot {
    color: #007fa5!important;
}

.bvgercolor1Border {
    border-color: #007fa5!important;
}

.markedOccurrence_bvgercolor1 {
    background: #007fa5 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}


.ui.bvgercolor2.label, .ui.bvgercolor2.labels .label , .bvgercolor2   {
    background-color: #d78926!important;
    border-color: #d78926!important;
    color: #fff!important;

}

.bvgercolor2Dot {
    color: #d78926!important;
}

.bvgercolor2Border {
    border-color: #d78926!important;
}

.markedOccurrence_bvgercolor2 {
    background: #d78926 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}


.ui.bvgercolor3.label, .ui.bvgercolor3.labels .label , .bvgercolor3   {
    background-color: #69351b!important;
    border-color: #69351b!important;
    color: #fff!important;

}

.bvgercolor3Dot {
    color: #69351b!important;
}

.bvgercolor3Border {
    border-color: #69351b!important;
}

.markedOccurrence_bvgercolor3 {
    background: #69351b !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}


.ui.bvgercolor4.label, .ui.bvgercolor4.labels .label , .bvgercolor4   {
    background-color: #c5c7c8!important;
    border-color: #c5c7c8!important;
    color: #fff!important;

}

.bvgercolor4Dot {
    color: #c5c7c8!important;
}

.bvgercolor4Border {
    border-color: #c5c7c8!important;
}

.markedOccurrence_bvgercolor4 {
    background: #c5c7c8 !important;
    border-radius: 4px!important;
    padding: 2px;
    display: inline-block;
}


