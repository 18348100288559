.searchBoxMobileButton {
    margin-Top: 6px!important;
    margin-Right: 6px!important;
    height: 45px!important;
    width: 66px!important;
    position: absolute!important;
    right : 0px!important;
    background: #F7F7F7!important;
    border-radius: 11px!important;
}


.searchBoxMobileInputSemanticUI {
    height : 57px!important;
    margin-Left: 15px!important;
    width: 95%!important;
}

#searchBoxMobileInput {
    background: #FFFFFF!important;
    box-Shadow: 0px 10px 25px rgba(0, 0, 0, 0.05)!important;
    border-Radius: 14px!important;
    padding-left: 70px!important;
}

.or {
    position: relative;
    margin-right: 10px !important;
}

.or:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate3d(0,-50%,0);
    right: -7px;
    height: 15px;
    width: 2px;
    background-color: black;
    border-radius: 2px;
}