.navigationButtons {
    color: rgb(51, 51, 51)!important;
    border-radius: 8px!important;
    margin-left: 14px!important;
}

#sideMenuCacheViewAccordionComputer {
    background: #FCFCFC!important;
    box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.04)!important;
    border-radius: 14px!important;
    width: 100%!important;
    float: right!important;
    padding-bottom: 43px!important;
    filter :drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15))!important;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#sideMenuCacheViewAccordionComputer::-webkit-scrollbar {
    display: none;
}

#sideMenuCacheViewAccordionFirstSegmentComputer {
    padding-left: 42px;
    padding-top: 37px;
}

#currentScrollPositionString {
    margin-left: 90px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 35px;
    color: #636363;
}

#barIconCacheView {
    margin-top: 37px;
    margin-left: 42px;
    margin-right: 60%;
}

#backButton {
    background: #F9F9F9!important;
    border-radius: 8px!important;
    color: rgba(0, 0, 0, 0.87)!important;
    font-weight: 570;
}

#activeMarkingButton {
    background: #FFFFFF!important;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.07)!important;
    border-radius: 8px!important;
}

#notActiveMarkingButton {
    border: 0px!important;
    box-shadow: unset!important;
}

#switchShowMarkingContainer {
    margin-top: 36px;
    background: #F5F8F8;
    border-radius: 8px;
    width: fit-content;
    height: fit-content;
    display: inline-block;
    padding: 6px;
    margin-left: 42px;
}

#stepForwardIconContainer {
    margin-top: 36px;
    background: #F5F8F8;
    border-radius: 8px;
    width: fit-content;
    height: fit-content;
    display: inline-block;
    padding: 6px;
    margin-left: 42px;
}
#stepForwardIconButton {
    background: #F5F8F8;
    border: 0px!important;
    box-shadow: unset!important;
}

#test1234 {

}