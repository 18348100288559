@import '../../index.css';

.leftColumn {
        background: #FCFCFC!important;
        box-shadow: 0px 9px 25px rgba(0, 0, 0, 0.05)!important;
        height: 610px!important;
        border-radius: 14px 0px 0px 14px!important;
        padding: 0px!important;
        width: 498px!important;

}

.rightColumn {
        background: linear-gradient(162.18deg, #535F8F 5.88%, #0F1B49 94.29%)!important;
        box-Shadow: 0px 9px 25px rgba(0, 0, 0, 0.05)!important;
        border-Radius: 0px 14px 14px 0px!important;
        height: 610px!important;
        padding: 0px!important;
        width: 498px!important;
}

.loginTitle {
        margin-left: 79px!important;
        margin-top: 59px!important;
        margin-bottom: 20px!important;
        font-family: Poppins!important;
        font-style: normal!important;
        font-weight: 700!important;
        font-size: 16px!important;
        line-height: 35px!important;
        color: #333333!important;
        text-align: left!important;
}

.descriptionTitle {
        margin-left: 69px!important;
        margin-top: 59px!important;
        margin-bottom: 20px!important;
        font-family: Poppins!important;
        font-style: normal!important;
        font-weight: 700!important;
        font-size: 16px!important;
        line-height: 35px!important;
        color: #FFFFFF!important;
        text-align: left!important;
}

.descriptionLogin {
        white-space: pre-line;
        text-align: left!important;
        margin-left: 69px!important;
        font-family: Poppins!important;
        font-style: normal!important;
        font-weight: 500!important;
        font-size: 16px!important;
        line-height: 28px!important;
        width: 360px!important;
        height: 350px!important;
        color: rgba(255, 255, 255, 0.79)!important;
}

.leftButtons {
        height: 55px!important;
        width: 340px!important;
        margin-Right: 79px!important;
        margin-Left: 79px!important;
}

.rightButtons {
        height: 55px!important;
        width: 275px!important;
        margin-Right: 79px!important;
        margin-Left: 154px!important;
        background: rgba(255, 255, 255, 0.17)!important;
        border-radius: 8px!important;
}



.inputIcon {
        color: #333333!important;
        opacity: 1.0!important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #333333!important;
        opacity: 0.5;
}

.inputField {
        background: #FFFFFF!important;
        box-Shadow: 0px 10px 25px rgba(0, 0, 0, 0.05)!important;
        border-Radius: 14px!important;
        font-Family: Poppins!important;
        font-Style: normal!important;
        font-Weight: 500!important;
        font-Size: 16px!important;
        lineHeight: 35px!important;
        padding-left: 50px!important;
        color: #333333!important;
}

.upperInput {
        color: #333333!important;
        height: 71px!important;
        margin-Left: 79px!important;
        margin-Right: 79px!important;
        margin-Bottom: 12px!important;
}
.lowerInput {
        color: #333333!important;
        height: 71px!important;
        margin-Left: 79px!important;
        margin-Right: 79px!important;
        margin-Bottom: 144px!important;
        margin-Bottom: 144px!important;
}

#passwordInput {
        padding-left: 50px!important;
}
#nameInput {
        padding-left: 50px!important;
}

#loginButton {
        font-family: Poppins!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 16px!important;
        line-height: 35px!important;

        align-items: center!important;
        text-align: center!important;
        border-radius: 8px!important;
        color: #FFFFFF!important;
}

#newLoginButton {
        background: #C8C8C8!important;
        border-radius: 8px!important;
        font-family: Poppins!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 16px!important;
        line-height: 35px!important;
        align-items: center!important;
        text-align: center!important;
        color: #FFFFFF!important;
        margin-top: 12px!important;
}

#passwordRecoveryButton {
        background: #C8C8C8!important;
        border-radius: 8px!important;
        font-family: Poppins!important;
        font-style: normal!important;
        font-weight: 600!important;
        font-size: 16px!important;
        line-height: 35px!important;
        align-items: center!important;
        text-align: center!important;
        margin-top: 12px!important;
}