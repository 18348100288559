.mainGridColumn {
    padding-Bottom: 0px!important;
}

.helpButtons {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 16px!important;
    line-height: 35px!important;
    color: #BEBEBE;
    cursor: pointer!important;
    margin-left: 23px!important;
}

.helpButtons:hover {
    color: rgba(0, 0, 0, 0.95)!important;
    box-shadow: 0px 0px 0px 1px transparent inset!important;
}

.helpButtons:active {
    color: rgba(0, 0, 0, 0.95)!important;
    box-shadow: 0px 0px 0px 1px transparent inset!important;
}

.stickySearchRow {
    padding-top: 30px!important;
    background-color: #fff!important;
    box-shadow: 0 0 25px 0 rgb(0 0 0 / 15%)!important;
    padding-bottom: 15px!important;
}
#firstMenuItem {
    margin-Bottom: 63px!important;
}

#lev4Image {
    max-height: 56px;
    width: unset;
}
#userAndButtonsPopup {
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.11);
    border-radius: 14px;
}

#barIcon {
    float: right;
    margin-Top: 25px;
}

/*.file.pdf.big.icon {*/
/*    font-size: 1.4em;*/
/*    padding: 0 15px;*/
/*}*/

.or {
    position: relative;
    margin-right: 10px !important;
}

.or:before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate3d(0,-50%,0);
    right: -7px;
    height: 15px;
    width: 2px;
    background-color: black;
    border-radius: 2px;
}

