.sideMenuToggleMobile {
    height: 45px!important;
    width: 66px!important;
    background: #F7F7F7!important;
    border-radius: 11px!important;
}

#currentScrollPositionStringMobile {
    margin-left: 20px;
    margin-right: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 35px;
    color: #636363;
}


#sideMenuCacheViewAccordionFirstSegmentMobile {
    width: 95%;
    padding-top: 37px;
}