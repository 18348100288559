.accordionIconSideContentCacheView {
    float: right!important;
    margin-Top : 6px!important;
}

.accordionTitleCacheView {
    padding-Top: 2px!important;
}

.generalGridRowCacheView {
    margin:0px!important;
}

.accordionHeaderSideContentCacheView {
    font-family : Poppins!important;
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 16px!important;
    /* identical to box height, or 318% */
    color: #636363!important;
    display :inline!important;
    overflow-wrap: anywhere!important;
    margin: 4px 0px 0px 5px!important;
    line-height: 25px!important;
}

.accordionLabelSideContentCacheView {
    padding-bottom: 9px!important;
    padding-top: 9px!important;
    /*padding-bottom: ;*/
    background:#FAFAFA!important;
    margin-Left : 6%!important;
    margin-Right : 5%!important;
    margin-bottom: 10px!important;
    width: 90%!important;
    height: 100%!important;
    border: 1px solid #F2F2F2!important;
    border-radius: 8px!important;
    box-sizing: border-box!important;
    cursor: pointer!important;
    display:grid!important;
    grid-template-columns: 1fr 20px!important;
    grid-gap: 10px!important;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.06);
}