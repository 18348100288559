#timeoutMessageDiv {
    margin-Left:27%!important;
}

.lessAndMoreButton {
    box-shadow: unset!important;
    padding-left: 0px!important;
    padding-top: 0px!important;
    color: #537DBD!important;
}

.lessAndMoreButtonWrapper {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 11px!important;
    color: #537DBD!important;
}

.lessAndMoreButtonText  {
    margin-left: 11px!important;
    display: inline!important;
}



.checkBoxCustomStyle {

}

.iconButtonCustom {
    margin-right: 0px!important;
}

.countCustomStyle {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 11px!important;
    color: rgba(0, 0, 0, 0.87)!important;
}

.menuItemCustomStyle {
    padding-top: 0px!important;
    padding-bottom: 14px!important;
}

.ui.checkbox .box:before, .ui.checkbox label:before {
    width: 17px!important;
    height: 17px!important;
    border: 2px solid #7C7C7C!important;
    border-radius: 3px!important;
}

.ui.checkbox input:checked ~ .box:after, .ui.checkbox input:checked ~ label:after {
    color : #7C7C7C!important;
}

.customLabelStyle {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 11px!important;
    color: #7C7C7C!important;
}

#dateBoxButtonSideContent{
    background: #FFFFFF!important;
    box-Shadow: 0px 10px 25px rgba(0, 0, 0, 0.05)!important;
    border-Radius: 14px!important;
    padding-left: 10px!important;
}

#searchBoxButtonSideContent{
    background: #FFFFFF!important;
    box-Shadow: 0px 10px 25px rgba(0, 0, 0, 0.05)!important;
    border-Radius: 14px!important;
    padding-left: 40px!important;
}

.react-checkbox-tree {
    color: #444;
    font-family: "Roboto", sans-serif;
    max-height: 400px;
    overflow: auto;
}

.clickable-labels {
    display: flex;
}

.expand-all-container {
    max-width: 400px;
}

.rct-node-icon .far {
    width: 1em;
    text-align: center;
}

.filter-container > .filter-text {
    display: block;
    margin-bottom: .75rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    background-clip: padding-box;
    padding: .375rem .75rem;
    line-height: 1.5;
    font-size: 1rem;
}