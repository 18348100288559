
#dummyClass::before {
    top:31px
}

.searchBoxInputSemanticUI {
    min-height : 61px!important;
    margin-Left: 100px!important;
}

.searchBoxInputSemanticUIMobile {
    min-height : 61px!important;
    margin-left: 2.5%;
    margin-right: 3%;
}

#searchBoxButton {
    margin-Top: 6.5px!important;
    margin-Right: 6.5px!important;
    height: 48px!important;
    position: absolute!important;
    right : 0px!important;
    border-radius: 8px!important;
}
.searchBoxInput {
    background: #FFFFFF!important;
    box-Shadow: 0px 10px 25px rgba(0, 0, 0, 0.05)!important;
    border-Radius: 14px!important;
}



#searchBoxInputFieldPosition {
    padding-left: 70px!important;
}

#searchBoxInput1row {
    padding-bottom: 50px!important;
    padding-left: 70px!important;
}

#searchBoxInput2row {
    padding-bottom: 80px!important;
    padding-left: 70px!important;
}

#searchBoxInput3row {
    padding-bottom: 110px!important;
    padding-left: 70px!important;
}

