.languageSwitch {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 16px!important;
    line-height: 35px!important;
    color: #333333!important;

    cursor: pointer!important;
}

.languageSwitchDashboardComputer {
    float: right!important;
    margin-Top: 15px;
    margin-Right: 63px;
}

.languageSwitchDashboardMobile {
    float: right!important;
    margin-Top: 16px;
    margin-Right: 35px;
}

.form .lang_wrap {
    position: absolute;
    display: flex;
    background-color: #FFFFFF;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 13px 30px 0 rgb(0 0 0 / 10%);
    transform: translate3d(10px,0,0);
    opacity: 0;
    transition: all 0.15s ease-out;
    pointer-events: none;
    z-index: 5;
    top: -5px;
}

a.navig {
    position: relative;
    padding: 7px 12px;
    background-color: #10549E;
    color: #FFFFFF;
    border-radius: 30px;
    font-size: 0.8rem;
    font-weight: 700;
    text-align: center;
}

a.navig.loose {
    background-color: rgba(0,0,0,0);
    color: #313131;
    margin-right: 10px;
}

a.navig.loose.selected_page {
    background-color: #313131;
    color: #FFFFFF;
}