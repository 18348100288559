.itemGroupHeaderNormal {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 12px!important;
    line-height: 35px!important;
    color: #333333!important;
}

.itemGroupHeaderItalic {
    font-family: Poppins!important;
    font-style: italic!important;
    font-weight: 400!important;
    font-size: 12px!important;
    line-height: 35px!important;
    color: rgba(0, 0, 0, 0.87)!important;
}

#itemGroupHeaderWrapper {
    float: right;
    margin-Top: 30px;
}