.image {
    float: left;
    width: 50%;
    height: 100%;
    text-align: center;
}
.inner-wrapper {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding-bottom: 30px;
    padding-top: 30px;
    box-sizing: border-box;
}


.content-wrapper {
    position: absolute;
    width: 100%;
    top: 37px;
    bottom: 0;
}

.image .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.content img {
    width: auto;
    max-width: 98%;
    max-height: 700px;
    vertical-align: middle;
    position: relative;
    height: auto;
    margin: 0 auto;
}

.text-wrapper {
    display: table;
    float: right;
    height: 100%;
    width: 49%;
}

.text {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}

.h-error{
    color: #27559A;
}
Link {
    color: #27559A;
    font-weight: normal;
    font-size: 1.2em;
    width: 400px;
    margin: 10px auto 0;
    text-align: justify;
}