.titleLabel {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
    color: #939393;
    margin-right: 27px;
    white-space: normal;
}

