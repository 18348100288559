.userName {
    float: right;
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 16px!important;
    line-height: 35px!important;
    color: #333333!important;
    cursor: pointer!important;
}

.userNameDashboardComputer {
    float: right!important;
    margin-Top: 15px;
    margin-Right: 63px;
}

.userNameDashboardMobile {
    float: right!important;
    margin-Top: 16px;
    margin-Right: 35px;
}