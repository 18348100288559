
.ui.divided.items > .item:first-child {
    padding-top: 12px!important;
}


.infiniteScrollerItem {
    padding-bottom: 10.5px!important;
    background: #FFFFFF!important;
    box-Shadow: 0px 10px 14px rgba(0, 0, 0, 0.04)!important;
    border-Radius: 14px!important;
    filter :drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15))!important;
    margin-Bottom: 28px!important;
    margin-right: 0px!important;
    width: 100%;
    padding-top: 25px!important;
    padding-Right : 46px!important;
    padding-Left : 46px!important;

}

.infiniteScrollerItemHeader {
    font-family : Poppins!important;
    font-style: normal!important;
    font-weight : 700!important;
    font-size: 20px!important;
    line-height: 35px!important;
    color: #333333!important;
    display :inline!important;
}


.infiniteScrollerItemLabel {
    cursor: pointer!important;
    display: inline!important;
    maxHeight:35px!important;
    paddingTop : 5px!important;
    background: #F1F1F1!important;
    border: 0.5px solid #D7D7D7!important;
    boxSizing: border-box!important;
    borderRadius: 4px!important;
}



.infiniteScrollerItemSubHeader {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 11px!important;
    line-height: 35px!important;
    color: #9E9E9E!important;
    display: inline!important;
    margin-Right:10px!important;
    margin-Top: 10px!important;
    color: #333333!important;
}

.infiniteScrollerItemDescription {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 300!important;
    font-size: 16px!important;
    line-height: 28px!important;
}

.markedLink {
    color: rgba(51, 51, 51, 0.34)!important
}

.infiniteScrollerMoreOrLessButton {
    background-color: transparent!important;
    font-size: 0.9rem!important;
    background: transparent!important;
    font-family: Poppins!important;
    cursor: pointer!important;
    border: 0px solid rgb(51, 51, 51)!important;
    color: rgb(51, 51, 51)!important;
    box-shadow: inset 0 0 0 2px!important;
}

#scrollerItem {
    padding-top: 25px!important;
}