.helpButtons {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 16px!important;
    line-height: 35px!important;
    color: #BEBEBE;
    cursor: pointer!important;
    margin-left: 23px!important;
}

.helpButtons:hover {
    color: rgba(0, 0, 0, 0.95)!important;
    box-shadow: 0px 0px 0px 1px transparent inset!important;
}

.helpButtons:active {
    color: rgba(0, 0, 0, 0.95)!important;
    box-shadow: 0px 0px 0px 1px transparent inset!important;
}

#userAndButtonsPopup {
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.11);
    border-radius: 14px;
}

.languageSwitchCacheViewMobile {
    margin-Top: 15px!important;
    margin-Right: 63px!important;
    margin-left: 60px;
}