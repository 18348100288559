.ui.sidebar {
    overflow-y: unset!important;
}

.pushable {
    overflow-x: unset!important;
}
.ui.divided.items > .item:first-child {
    padding-top: 14px!important;
}


.infiniteScrollerItemMobile {
    background: #FCFCFC!important;
    box-Shadow: 0px 10px 14px rgba(0, 0, 0, 0.04)!important;
    border-Radius: 14px!important;
    filter :drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15))!important;
    margin-Bottom: 15px!important;
    margin-right: 15px!important;
    margin-left: 0px!important;
    width: 96%!important;
    padding-Right : 20px!important;
    padding-Left : 20px!important;

}

.infiniteScrollerItemMobileHeader {
    font-family : Poppins!important;
    font-style: normal!important;
    font-weight : 700!important;
    font-size: 20px!important;
    line-height: 35px!important;
    color: #333333;
    display :inline!important;
}

.infiniteScrollerItemMobileLabel {
    cursor: pointer!important;
    display: inline!important;
    maxHeight:35px!important;
    paddingTop : 5px!important;
    background: #F1F1F1!important;
    border: 0.5px solid #D7D7D7!important;
    boxSizing: border-box!important;
    borderRadius: 4px!important;
}



.infiniteScrollerItemMobileSubHeader {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 11px!important;
    line-height: 35px!important;
    color: #9E9E9E!important;
    display: inline!important;
    margin-Right:10px!important;
    margin-Top: 10px!important;
}

.infiniteScrollerItemMobileDescription {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 300!important;
    font-size: 16px!important;
    line-height: 28px!important;
}