

.label-wrapper-active {
    z-index: 25;

}

.label-wrapper {
    white-space: nowrap;
    font-family: Poppins!important;
    position: relative;
    padding: 7px 10px;
    font-size: 0.9rem;
    color: #FFF;
    border-radius: 5px;
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
    transition: all 0.15s ease-out;
}

.display-element {
    transform: rotateY(0deg)!important;
    pointer-events: all!important;
    box-shadow: 0px 0 0px 0 rgba(0, 0, 0, 0.15)!important;
}

.label-wrapper span {

    position: absolute;
    height: 100%;
    top: 0;
    left: calc(100% - 5px);
    display: flex;
    align-items: center;
    padding: 0 3px 0 12px;
    border-radius: 0 5px 5px 0;
    transition: all 0.15s ease-out;
    transform: rotateY(-90deg);
    transform-origin: 0% 50%;
    pointer-events: none;
}


.label-wrapper span i {
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.6);
}

.span button {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    margin: 0;
    padding: 0;
    height: calc(100% - 6px);
    width: 30px;
    border-radius: 3px;
    cursor: pointer;
    background-position: center;
    background-size: 9px;
    background-repeat: no-repeat;
    transition: all 0.15s ease-out;
    background-color: rgba(255, 255, 255, 0.3);
}



.label-wrapper span button:hover {
    transform: scale(0.9);
}

.iconCursorPointerCustom {
    cursor: pointer;
}