
.paddingBottomGrindRowActivatedMobile {
    padding-bottom: 30px!important;
}
.paddingBottomGrindRowDeactivatedMobile {
    padding-Bottom: 0px!important;
}


.generalGridRowMobile {
    margin:0px!important;
}

.accordionHeaderSideContentMobile {
    font-family : Poppins!important;
    font-style: normal!important;
    font-weight : 500!important;
    font-size: 16px!important;
    line-height: 35px!important;
    color: #949494!important;
    display :inline!important;
    margin-left : 14px!important;
    overflow-wrap: anywhere!important;
    margin: 7px 0 0!important;
    line-height: 25px!important;
}

.accordionLabelSideContentMobile {
    background:#FAFAFA!important;
    border: 0.5px solid #DEDEDE!important;
    margin-Left : 6%!important;
    margin-Right : 5%!important;
    width: 90%!important;
    border-radius: 8px!important;
    box-sizing: border-box!important;
    cursor: pointer!important;
    display:grid!important;
    grid-template-columns: 1fr 20px!important;
    grid-gap: 10px!important;
}

.accordionAccordionSideContentMobile {
    padding-Bottom: 42px!important;
    background: #FCFCFC!important;
    box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.04)!important;
    border-radius: 14px!important;
    width: 100%!important;
    float: right!important;
    filter :drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.15))!important;
    margin-left: 15px!important;
    margin-top: 20px!important;


}

.accordionIconSideContentMobile {
    float: right!important;
    margin-Top:7px!important;
}

.accordionTitleMobile {
    padding-Top: 2px!important;
}

.removeFilterLabelMobile {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 11px!important;
    line-height: 35px!important;
    float: right!important;
    cursor: pointer!important;
    padding: 0px!important;
    padding-left: 10px!important;
    padding-right: 5px!important;
    margin-right: 14px!important;
}

.accordionHeaderMobile {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 12px!important;
    line-height: 35px!important;
    color: #333333!important;
    padding-left: 26px!important;
    padding-top: 21px!important;
    padding-bottom: 29px!important;
}


.ui.left.visible.sidebar {
    box-shadow: unset!important;
    border-radius: 8px!important;
}