.markedHtmlContentWrapper {
    font-family: Poppins!important;
    position: relative;
    padding: 7px 10px;
    font-size: 1rem;
    user-select: none;
    cursor: default;
    -webkit-user-select: none;
    transition: all 0.15s ease-out;
}

.markedHtmlContentWrapperActive {
    z-index: 25;
}

.markedHtmlContentWrapper span {
    position: absolute;
    height: 100%;
    top: 0;
    left: calc(100% - 5px);
    display: flex;
    align-items: center;
    padding: 0 3px 0 12px;
    border-radius: 0 5px 5px 0;
    transition: all 0.15s ease-out;
    transform: rotateY(-90deg);
    transform-origin: 0% 50%;
    pointer-events: none;
}

.markedHtmlContentWrapper span i {
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.6);
}

.markedHtmlContentWrapper span button:hover {
    transform: scale(0.9);
}

.displayMarkedHtmlContentDetails {
    transform: rotateY(0deg)!important;
    pointer-events: all!important;
    box-shadow: 7px 0 15px 0 rgba(0, 0, 0, 0.15)!important;
}
