#customContentSegment {
    background: #FCFEFE;
    padding: 0px;
    box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.04);
    border-radius: 14px;
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 300!important;
    font-size: 16px!important;
    line-height: 28px!important;
}

#infiniteScrollerItemDescription {
    font-family: Poppins!important;
    font-style: normal!important;
    font-weight: 300!important;
    font-size: 16px!important;
    line-height: 28px!important;
}

